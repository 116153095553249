import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';

if (document.getElementById('recruitment-form')) {
  ReactDOM.render(
      <App />,
      document.getElementById('recruitment-form')
  );
}
