import React from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';

import IUGComponent from '../../IUGComponent'

class StepFour extends IUGComponent {
  componentDidMount() {
    const { onFileChange } = this.props;
    const fileInputNames = ['cni_file', 'last_degree_file', 'cv_file', 'cover_letter_file'];
    fileInputNames.forEach(fileInputName => {
      (new Dropzone(`#${fileInputName}`, {
        url: '/api/recruitment/upload',
        acceptedFiles: 'image/*,application/pdf',
        maxFiles: 1,
        addRemoveLinks: true
      }))
        .on('removedfile', function (file) {
          const response = '';
          onFileChange(fileInputName, response);
        })
        .on('success', function (file, response) {
          onFileChange(fileInputName, response);
        });
    });
  }

  validateForm = () => {
    const { form: {
      cni_file,
      cv_file,
      last_degree_file,
      cover_letter_file
    }} = this.props;

    if (
      cni_file === '' ||
      cv_file === '' ||
      cover_letter_file === '' ||
      last_degree_file === ''
    ) {
      toastr.error(this.trans.get('Vous devez ajouter tous les fichiers'));
    } else {
      this.props.handleCompleteStep();
    }
  }

  render() {
    const {
      stepInfo: { number, disabled },
      title,
      description,
      current,
      handleStepBack,
    } = this.props;

    return (
      <div className={`kt-step step-4 ${number === current ? '' : 'd-none'}`}>
        <div className="step-4__header mb-2">
          <h1 className='step-4__title'>{title}</h1>
          <p>{description}</p>
        </div>
        <div className="alert alert-solid-warning alert-bold mb-4" role="alert">
          <div className="alert-text">{this.trans.get("Les fichiers supportés sont PNG, JPG ou PDF sont acceptés. Patientez de voir la flêche ✔️ sur tous vos fichiers avant de continuer.")}</div>
        </div>
        <div className="row mb-4">
          <div className="col-md-6">
            <div className={`dropzone`} action="/app" id="cni_file">
              <div className="dropzone-msg dz-message needsclick">
                <h3 className="dropzone-msg-title">{this.trans.get("Carte Nationale d'identité")}</h3>
                <span className="dropzone-msg-desc">
                  {this.trans.get('Déposez le fichier ici ou cliquez pour le télécharger. PNG, JPG ou PDF sont acceptés')}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`dropzone`} action="/app" id="last_degree_file">
              <div className="dropzone-msg dz-message needsclick">
                <h3 className="dropzone-msg-title">{this.trans.get('Diplôme le plus élevé')}</h3>
                <span className="dropzone-msg-desc">
                  {this.trans.get('Déposez le fichier ici ou cliquez pour le télécharger. PNG, JPG ou PDF sont acceptés')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className={`dropzone`} action="/app" id="cv_file">
              <div className="dropzone-msg dz-message needsclick">
                <h3 className="dropzone-msg-title">Curriculum Vitae (CV)</h3>
                <span className="dropzone-msg-desc">
                  {this.trans.get('Déposez le fichier ici ou cliquez pour le télécharger. PNG, JPG ou PDF sont acceptés')}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`dropzone`} action="/app" id="cover_letter_file">
              <div className="dropzone-msg dz-message needsclick">
                <h3 className="dropzone-msg-title">{this.trans.get('Lettre de Motivation')}</h3>
                <span className="dropzone-msg-desc">
                  {this.trans.get('Déposez le fichier ici ou cliquez pour le télécharger. PNG, JPG ou PDF sont acceptés')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='buttons-action'>
          <button className='btn btn-default' onClick={handleStepBack}>
            {this.trans.get('Précedent')}
          </button>
          <button className='btn btn-primary' onClick={this.validateForm} disabled={disabled}>
            {this.trans.get('Suivant')}
          </button>
        </div>
      </div>
    );
  }
}

StepFour.propTypes = {
  stepInfo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleCompleteStep: PropTypes.func.isRequired,
  handleStepBack: PropTypes.func,
  onFileChange: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired
};

StepFour.defaultProps = {
  handleStepBack: () => {}
};

export default StepFour;
