import React from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';

import IUGComponent from '../../IUGComponent';

class StepFour extends IUGComponent {
  componentDidMount() {
    const { onFileChange } = this.props;
    const fileInputNames = ['last_degree_file', 'birth_certificate'];
    fileInputNames.forEach(fileInputName => {
      (new Dropzone(`#${fileInputName}`, {
        url: '/api/admission/upload',
        acceptedFiles: 'image/*,application/pdf',
        maxFiles: 1,
        addRemoveLinks: true
      }))
        .on('removedfile', function (file) {
          const response = '';
          onFileChange(fileInputName, response);
        })
        .on('success', function (file, response) {
          onFileChange(fileInputName, response);
        });
    });
  }

  validateForm = () => {
    const { form: {
      last_degree,
      last_degree_year,
      last_degree_school,
      last_degree_file,
      birth_certificate
    }} = this.props;

    if (
      last_degree_school === '' ||
      last_degree_year === '' ||
      last_degree === '' ||
      last_degree_file === null ||
      birth_certificate === null
    ) {
      toastr.error(this.trans.get('Vous devez renseignez toutes les informations'));
    } else {
      this.props.handleCompleteStep();
    }
  }

  render() {
    const {
      stepInfo: { number, disabled },
      title,
      description,
      current,
      handleStepBack,
      onFormChange,
      form: {
        last_degree,
        last_degree_year,
        last_degree_school
      }
    } = this.props;

    return (
      <div className={`kt-step step-4 ${number === current ? '' : 'd-none'}`}>
        <div className="step-4__header">
          <h1 className='step-4__title'>{title}</h1>
          <p>{description}</p>
        </div>
        <div className="alert alert-solid-warning alert-bold mb-4" role="alert">
          <div className="alert-text">{this.trans.get("Les fichiers supportés sont PNG, JPG ou PDF sont acceptés. Patientez de voir la flêche ✔️ sur tous vos fichiers avant de continuer.")}</div>
        </div>
        <div className='row'>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-8 form-group">
                <label>{this.trans.get('Dernier Diplôme')}</label>
                <input type="text"
                   className={`form-control`}
                   name="last_degree"
                   value={last_degree}
                   aria-describedby="last_degree-error"
                   onChange={onFormChange}
                   placeholder={this.trans.get('Ex. Bac C, BTS Commerce, HND...')}
                />
              </div>
              <div className="col-md-4 form-group">
                <label>{this.trans.get("Année d'obtention")}</label>
                <input
                  type="number"
                  min="2010"
                  max={`${(new Date()).getFullYear()}`}
                  step="1"
                  className={`form-control`}
                  name="last_degree_year"
                  value={last_degree_year}
                  aria-describedby="last_degree_year-error"
                  onChange={onFormChange}
                  placeholder={this.trans.get('Ex. 1991, 2018, ...')}
                />
              </div>
            </div>
            <div className="form-group">
              <label>{this.trans.get('Dernier Etablissement')}</label>
              <input type="text"
                 className={`form-control`}
                 name="last_degree_school"
                 value={last_degree_school}
                 aria-describedby="last_degree_school-error"
                 onChange={onFormChange}
                 placeholder={this.trans.get('Ex. Institut Universitaire du Gofle de Guinée, ...')}
              />
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className={`dropzone`} action="/app" id="last_degree_file">
              <div className="dropzone-msg dz-message needsclick">
                <h3 className="dropzone-msg-title">{this.trans.get('Copie Certifiée du Dernier diplôme')}</h3>
                <span className="dropzone-msg-desc">{this.trans.get('Déposez le fichier ici ou cliquez pour le télécharger. PNG, JPG ou PDF sont acceptés')}</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className={`dropzone`} action="/app" id="birth_certificate">
              <div className="dropzone-msg dz-message needsclick">
                <h3 className="dropzone-msg-title">{this.trans.get('Copie Certifié Acte de naissance')}</h3>
                <span className="dropzone-msg-desc">{this.trans.get('Déposez le fichier ici ou cliquez pour le télécharger. PNG, JPG ou PDF sont acceptés')}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='buttons-action'>
          <button className='btn btn-default' onClick={handleStepBack}>
            {this.trans.get('Précedent')}
          </button>
          <button className='btn btn-primary' onClick={this.validateForm} disabled={disabled}>
            {this.trans.get('Suivant')}
          </button>
        </div>
      </div>
    );
  }
}

StepFour.propTypes = {
  stepInfo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleCompleteStep: PropTypes.func.isRequired,
  handleStepBack: PropTypes.func,
  onFormChange: PropTypes.func.isRequired,
  onFileChange: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired
};

StepFour.defaultProps = {
  handleStepBack: () => {}
};

export default StepFour;
