import React from 'react';
import Lang from 'lang.js';

export default class IUGComponent extends React.Component {
  constructor(props) {
    super(props);

    Lang.prototype._getMessage = function(key, locale) {
      locale = locale || this.getLocale();

      if (this.messages[locale] === undefined) {
        locale = this.getFallback();
      }

      if (this.messages[locale][key] === undefined) {
        locale = this.getFallback();
      }

      if (this.messages[locale][key] === undefined) {
        return null;
      }

      // Added this one - if key value doesn't found, return to fallback
      // To handle this case: {"Hello: ""}
      if (! this.messages[locale][key]) {
        locale = this.getFallback();
      }

      return this.messages[locale][key];
    }

    this.trans = new Lang({ messages, locale: default_locale, fallback: fallback_locale });
  }
}
