import React from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';

const StepTwo = ({
  title,
  description,
  stepInfo: { disabled, number },
  onChange,
  current,
  loading,
  handleCompleteStep,
  handleStepBack,
  trans,
  form: {
    gender
  }
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    handleCompleteStep();
  };

  return (
    <div className={`kt-step step-2 ${number === current ? '' : 'd-none'}`}>
      <div className="step-2__header mb-5">
        <h1 className='step-2__title'>{title}</h1>
        <p>{description}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="kt-form__section kt-form__section--first">
          <div className="form-group">
            <label>{trans.get('Sexe')}</label>
            <div className="kt-radio-list d-flex">
              <label className="kt-radio">
                <input
                  type="radio"
                  name="gender"
                  checked={gender === 'M'}
                  value="M"
                  onChange={onChange}
                /> {trans.get('Homme')}
                <span />
              </label>
              <label className="kt-radio">
                <input
                  type="radio"
                  name="gender"
                  checked={gender === 'F'}
                  value="F"
                  onChange={onChange}
                /> {trans.get('Femme')}
                <span />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>{trans.get('Nom')}</label>
                <input
                  type="text"
                  className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                  name="first_name"
                  ref={register({ required: true })}
                  aria-describedby="name-error"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>{trans.get('Prénom')}</label>
                <input
                  type="text"
                  className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                  name="last_name"
                  ref={register({ required: true })}
                  aria-describedby="last-name-error"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>{trans.get('Numéro de Teléphone')}</label>
                <input
                  type="tel"
                  className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                  name="phone_number"
                  ref={register({ required: true })}
                  aria-describedby="phone_number-error"
                  onChange={onChange}
                  placeholder={trans.get('Ex.: 699 88 77 66')}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>{trans.get('Date de naissance')}</label>
                <div className="input-group date">
                  <input
                    type="date"
                     className={`form-control kt_datepicker ${errors.birth_date ? 'is-invalid' : ''}`}
                     name="birth_date"
                     ref={register({ required: true })}
                     aria-describedby="birth_date-error"
                     onChange={onChange}
                  />
                  <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="la la-calendar-check-o" />
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>{trans.get('Lieu de Naissance')}</label>
                <input
                  type="text"
                  className={`form-control ${errors.birth_place ? 'is-invalid' : ''}`}
                  name="birth_place"
                  ref={register({ required: true })}
                  aria-describedby="birth_place-error"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>{trans.get('Adresse Email')}</label>
                <input
                  type="email"
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  name="email"
                  ref={register({
                      required: 'Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: trans.get("Votre adresse email est invalide")
                      }
                    }
                  )}
                  aria-describedby="email-error"
                  onChange={onChange}
                  placeholder={trans.get('Ex.: monadresse@gmail.com')}
                />
                {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>{trans.get('Quartier')}</label>
                <input
                  type="text"
                  className={`form-control ${errors.quarter ? 'is-invalid' : ''}`}
                  placeholder="Eg.: Makepe"
                  name="quarter"
                  ref={register({ required: true })}
                  aria-describedby="quarter-error"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='buttons-action'>
          <button className='btn btn-default' onClick={handleStepBack}>
            {trans.get('Précedent')}
          </button>
          <button className='btn btn-primary' type='submit' disabled={disabled}>
            {trans.get('Suivant')}
          </button>
        </div>
      </form>
    </div>
  )
};

StepTwo.propTypes = {
  stepInfo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleCompleteStep: PropTypes.func.isRequired,
  handleStepBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired
}

export default StepTwo;
