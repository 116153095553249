import React from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import ReactQuill from 'react-quill';

import IUGComponent from '../../IUGComponent';

class StepFive extends IUGComponent {
  componentDidMount() {
    const sitekey = document.getElementById('admission');
    grecaptcha.render('recaptcha', {
      'sitekey' : sitekey.getAttribute('data-captcha'),
      'callback' : this.props.verifyCallback
    });
  }

  /**
   * Validate form
   */
  validateForm = () => {
    const { form } = this.props;

    if (form.request === '') {
      toastr.error(this.trans.get('Vous devez renseignez toutes les informations'));
    } else {
      this.props.submitForm();
    }
  }

  render() {
    const {
      stepInfo: { number },
      form: {
        formation_time,
        civility,
        name,
        lastname,
        email,
        phone_number,
        birthdate,
        birthdate_place,
        nationality,
        region,
        last_degree,
        last_degree_year,
        last_degree_school,
        last_degree_file,
        birth_certificate,
        request,
        receive_letter,
        receive_copy
      },
      formationName,
      specialityName,
      current,
      disabled,
      title,
      loading,
      description,
      handleStepBack,
      onFormChange,
      onQuillChange
    } = this.props;

    return (
      <div className={`kt-step step-5 ${number === current ? '' : 'd-none'}`}>
        <div className="step-5__header">
          <h1 className='step-5__title'>{title}</h1>
          <p>{description}</p>
        </div>
        <div className="row mb-5">
          <div className="col-md-8">
            <div className="form-group">
              <label>{this.trans.get('Rédiger votre demande')}</label>
              <ReactQuill
                value={request}
                onChange={onQuillChange}
                style={{ height: 250 }}
              />
            </div>
          </div>
        </div>
        <div className="form-group d-flex align-items-center kt-mt-30-mobile">
          <label className="form-label mr-4">{this.trans.get("Je souhaite recevoir la lettre d'information")}</label>
          <div>
            <span className="kt-switch kt-switch--outline kt-switch--icon kt-switch--primary kt-switch--sm">
              <label>
                <input
                  type="checkbox"
                  checked={receive_letter}
                  name="receive_letter"
                  onChange={onFormChange}
                />
                <span />
              </label>
            </span>
          </div>
        </div>
        <div className="form-group d-flex align-items-center">
          <label className="form-label mr-4">{this.trans.get("Je souhaite recevoir une copie de cet envoi")}</label>
          <div>
            <span className="kt-switch kt-switch--outline kt-switch--icon kt-switch--primary kt-switch--sm">
              <label>
                <input
                  type="checkbox"
                  checked={receive_copy}
                  name="receive_copy"
                  onChange={onFormChange}
                />
                <span />
              </label>
            </span>
          </div>
        </div>
        <div className="form-group">
          <div id="recaptcha" />
        </div>
        <div className="form-group">
          <button
            type='button'
            data-toggle="modal"
            data-target="#recapitulatif"
            className="btn btn-iug"
          >
            {this.trans.get('Afficher le récapitulatif de vos informations')}
          </button>
        </div>
        <div className='buttons-action'>
          <button className='btn btn-default' onClick={handleStepBack}>
            {this.trans.get('Précedent')}
          </button>
          <button className='btn btn-primary' onClick={this.validateForm} disabled={disabled}>
            {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />}
            {!loading && this.trans.get('Suivant')}
          </button>
        </div>

        <div className="modal fade" id="recapitulatif" tabIndex="-1" role="dialog" aria-labelledby="recapitulatifTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="recapitulatifTitle">{this.trans.get('Récapitulatif de vos informations')}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h4>{this.trans.get('Formation')}</h4>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <h6 className='kt-font-bolder'>{this.trans.get('Selectionnée')}</h6>
                    <label>{formationName}</label>
                  </div>
                  <div className='mr-4'>
                    <h6 className='kt-font-bolder'>{this.trans.get('Spécialitée')}</h6>
                    <label>{specialityName}</label>
                  </div>
                  <div>
                    <h6 className='kt-font-bolder'>{this.trans.get('Période')}</h6>
                    <label>{formation_time}</label>
                  </div>
                </div>

                <h4>{this.trans.get('Informations Personnelles')}</h4>
                <div className="d-flex align-items-center mb-3">
                  <div>
                    <h6 className='kt-font-bolder'>{this.trans.get('Civilité')}</h6>
                    <label>{civility}</label>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <h6 className='kt-font-bolder'>{this.trans.get('Nom')}</h6>
                    <label>{name}</label>
                  </div>
                  <div>
                    <h6 className='kt-font-bolder'>{this.trans.get('Prénom')}</h6>
                    <label>{lastname}</label>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <h6 className='kt-font-bolder'>{this.trans.get('Date de naissance')}</h6>
                    <label>{birthdate}</label>
                  </div>
                  <div>
                    <h6 className='kt-font-bolder'>{this.trans.get('Lieu de Naissance')}</h6>
                    <label>{birthdate_place}</label>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <h6 className='kt-font-bolder'>{this.trans.get('Numéro de Teléphone')}</h6>
                    <label>{phone_number}</label>
                  </div>
                  <div>
                    <h6 className='kt-font-bolder'>{this.trans.get('Adresse Email')}</h6>
                    <label>{email}</label>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <h6 className='kt-font-bolder'>{this.trans.get('Nationalité')}</h6>
                    <label>{nationality}</label>
                  </div>
                  <div>
                    <h6 className='kt-font-bolder'>{this.trans.get('Region')}</h6>
                    <label>{region}</label>
                  </div>
                </div>

                <h4>{this.trans.get('Précédente Formation')}</h4>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <h6 className='kt-font-bolder'>{this.trans.get('Dernier Diplôme')}</h6>
                    <label>{last_degree}</label>
                  </div>
                  <div>
                    <h6 className='kt-font-bolder'>{this.trans.get("Etablissement d'obtention")}</h6>
                    <label>{last_degree_school}</label>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <h6 className='kt-font-bolder'>{this.trans.get("Année d'obtention")}</h6>
                    <label>{last_degree_year}</label>
                  </div>
                </div>

                <h4>{this.trans.get('Documents')}</h4>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get("Copie Certifiée du Dernier diplôme")}</label>
                    {last_degree_file !== '' && <span className="flaticon2-correct text-primary" />}
                    {last_degree_file === '' && <span className="flaticon-exclamation-1 text-danger" />}
                  </div>
                  <div>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get("Copie Certifié Acte de naissance")}</label>
                    {birth_certificate !== '' && <span className="flaticon2-correct text-primary" />}
                    {birth_certificate === '' && <span className="flaticon-exclamation-1 text-danger" />}
                  </div>
                </div>

                <h4>{this.trans.get('Demande')}</h4>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <h6 className='kt-font-bolder'>{this.trans.get("Reception d'une copie")}</h6>
                    <label>{receive_copy === true ? this.trans.get('Oui') : this.trans.get('Non')}</label>
                  </div>
                  <div>
                    <h6 className='kt-font-bolder'>{this.trans.get("Reception Lettre d'information")}</h6>
                    <label>{receive_letter === true ? this.trans.get('Oui') : this.trans.get('Non')}</label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-brand" data-dismiss="modal">{this.trans.get('Fermer')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StepFive.propTypes = {
  stepInfo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  formationName: PropTypes.string.isRequired,
  specialityName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleCompleteStep: PropTypes.func.isRequired,
  handleStepBack: PropTypes.func,
  onFormChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

StepFive.defaultProps = {
  handleStepBack: () => {}
};

export default StepFive;
