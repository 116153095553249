import React from 'react';
import PropTypes from 'prop-types';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import useForm from 'react-hook-form';

const StepThree = ({
 stepInfo: { number, disabled },
 title,
 current,
 onFormChange,
 onDateChange,
 selectCountry,
 selectRegion,
 description,
 handleStepBack,
 handleCompleteStep,
 trans,
 form: {
   civility,
   nationality,
   region
 }
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    handleCompleteStep();
  }

  return (
    <div className={`kt-step step-3 ${number === current ? '' : 'd-none'}`}>
      <div className="step-3__header">
        <h1 className='step-3__title'>{title}</h1>
        <p>{description}</p>
        <p className='text-info'>{trans.get('Tous les champs du formulaire sont obligatoires*')}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>{trans.get('Civilité')}</label>
          <div className="kt-radio-list d-flex">
            <label className="kt-radio">
              <input
                type="radio"
                name="civility"
                checked={civility === 'Mr'}
                value="Mr"
                onChange={onFormChange}
              /> Mr
              <span />
            </label>
            <label className="kt-radio">
              <input
                type="radio"
                name="civility"
                checked={civility === 'Mme'}
                value="Mme"
                onChange={onFormChange}
              /> Mme
              <span />
            </label>
            <label className="kt-radio">
              <input
                type="radio"
                name="civility"
                checked={civility === 'Mlle'}
                value="Mlle"
                onChange={onFormChange}
              /> Mlle
              <span />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Nom')}</label>
              <input type="text"
                 className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                 name="name"
                 ref={register({ required: true })}
                 aria-describedby="name-error"
                 onChange={onFormChange}
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Prénom')}</label>
              <input type="text"
                 className={`form-control ${errors.lastname ? 'is-invalid' : ''}`}
                 name="lastname"
                 ref={register({ required: true })}
                 aria-describedby="lastname-error"
                 onChange={onFormChange}
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Numéro de Teléphone')}</label>
              <input type="tel"
                 className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                 name="phone_number"
                 ref={register({ required: true })}
                 aria-describedby="phone_number-error"
                 onChange={onFormChange}
                 placeholder={trans.get('Ex.: 600 00 00 00')}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Date de naissance')}</label>
              <div className="input-group date">
                <input type="date"
                   className={`form-control kt_datepicker ${errors.birthdate ? 'is-invalid' : ''}`}
                   name="birthdate"
                   ref={register({ required: true })}
                   aria-describedby="birthdate-error"
                   onChange={onDateChange}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="la la-calendar-check-o" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Lieu de Naissance')}</label>
              <input type="text"
                 className={`form-control ${errors.birthdate_place ? 'is-invalid' : ''}`}
                 name="birthdate_place"
                 ref={register({ required: true })}
                 aria-describedby="birthdate_place-error"
                 onChange={onFormChange}
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Adresse Email')}</label>
              <input type="email"
                 className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                 name="email"
                 ref={register({
                   required: 'Required',
                   pattern: {
                     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                     message: trans.get("Votre adresse email est invalide")
                   }
                 })}
                 aria-describedby="email-error"
                 onChange={onFormChange}
                 placeholder={trans.get('Ex.: student@iug-univ.com')}
              />
              {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Nationalité')}</label>
              <CountryDropdown
                value={nationality}
                name="nationality"
                classes='form-control kt_selectpicker'
                onChange={selectCountry} />
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Région')}</label>
              <RegionDropdown
                country={nationality}
                value={region}
                name="region"
                classes='form-control'
                onChange={selectRegion} />
            </div>
          </div>
        </div>
        <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed" />
        <h4 className='mb-5'>{trans.get("Parents / Tuteurs")}</h4>
        <div className="row">
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Nom du Père / Tuteur')}</label>
              <input type="text"
                 className={`form-control ${errors.father_name ? 'is-invalid' : ''}`}
                 name="father_name"
                 ref={register({ required: true })}
                 aria-describedby="father_name-error"
                 onChange={onFormChange}
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Occupation')}</label>
              <input type="text"
                 className={`form-control ${errors.father_occupation ? 'is-invalid' : ''}`}
                 name="father_occupation"
                 ref={register({ required: true })}
                 aria-describedby="father_occupation-error"
                 onChange={onFormChange}
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Numéro de Teléphone')}</label>
              <input type="tel"
                 className={`form-control ${errors.father_number ? 'is-invalid' : ''}`}
                 name="father_number"
                 ref={register({ required: true })}
                 aria-describedby="father_number-error"
                 onChange={onFormChange}
                 placeholder={trans.get('Ex.: 897 01 02 03')}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Nom de la Mère / Tutrice')}</label>
              <input type="text"
                 className={`form-control ${errors.mother_name ? 'is-invalid' : ''}`}
                 name="mother_name"
                 ref={register({ required: true })}
                 aria-describedby="mother_name-error"
                 onChange={onFormChange}
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Occupation')}</label>
              <input type="text"
                 className={`form-control ${errors.mother_occupation ? 'is-invalid' : ''}`}
                 name="mother_occupation"
                 ref={register({ required: true })}
                 aria-describedby="mother_occupation-error"
                 onChange={onFormChange}
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-4">
            <div className="form-group">
              <label>{trans.get('Numéro de Teléphone')}</label>
              <input type="tel"
                 className={`form-control ${errors.mother_number ? 'is-invalid' : ''}`}
                 name="mother_number"
                 ref={register({ required: true })}
                 aria-describedby="mother_number-error"
                 onChange={onFormChange}
                 placeholder={trans.get('Ex.: 897 01 02 03')}
              />
            </div>
          </div>
        </div>
        <div className='buttons-action'>
          <button className='btn btn-default' onClick={handleStepBack}>
            {trans.get('Précedent')}
          </button>
          <button className='btn btn-primary' type='submit' disabled={disabled}>
            {trans.get('Suivant')}
          </button>
        </div>
      </form>
    </div>
  );
}

StepThree.propTypes = {
  stepInfo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleCompleteStep: PropTypes.func.isRequired,
  handleStepBack: PropTypes.func,
  onFormChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired
};

StepThree.defaultProps = {
  handleStepBack: () => {}
};

export default StepThree;
