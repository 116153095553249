import React from 'react';
import PropTypes from 'prop-types';

import IUGComponent from '../../IUGComponent';

class StepFive extends IUGComponent {
  componentDidMount() {
    const { complete } = this.props;
    const sitekey = document.getElementById('recruitment-form');

    if (!complete) {
      grecaptcha.render('captcha', {
        'sitekey' : sitekey.getAttribute('data-captcha'),
        'callback' : this.props.verifyCallback
      });
    }
  }

  render() {
    const {
      stepInfo: { number },
      current,
      disabled,
      title,
      loading,
      complete,
      description,
      handleStepBack,
      submitForm,
      form: {
        profile,
        gender,
        first_name,
        last_name,
        birth_date,
        birth_place,
        quarter,
        email,
        phone_number,
        last_degree,
        last_degree_place,
        experience_year,
        status,
        fonction,
        courses,
        cni_file,
        cv_file,
        last_degree_file,
        cover_letter_file
      }
    } = this.props;

    const baseURL = document.head.querySelector('meta[name="base-url"]').content;

    return (
      <div className={`kt-step step-5 ${number === current ? '' : 'd-none'}`}>
        {!complete &&
          <div>
            <div className="step-5__header mb-5">
              <h1 className='step-5__title'>{title}</h1>
              <p>{description}</p>
            </div>
            <div className="form-group">
              <button
                type='button'
                data-toggle="modal"
                data-target="#recapitulatif"
                className="btn btn-iug"
              >
                {this.trans.get('Afficher le récapitulatif de vos informations')}
              </button>
            </div>
            <div className="kt-form__section kt-form__section--first">
              <div className="form-group">
                <div id="captcha" />
              </div>
            </div>
            <div className='buttons-action'>
              <button className='btn btn-default' onClick={handleStepBack}>
                {this.trans.get('Précedent')}
              </button>
              <button className='btn btn-primary' onClick={submitForm} disabled={disabled}>
                {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />}
                {!loading && this.trans.get('Envoyer ma demande')}
              </button>
            </div>
          </div>
        }

        {complete &&
          <div className={`step-final`}>
            <div className="step-final__header">
              <span className="flaticon2-correct text-primary" />
              <h1 className='step-final__title'>{this.trans.get("Félicitation")}</h1>
              <p>{this.trans.get("Merci d'avoir postulé dans notre etablissement. Votre dossier sera vérifié et nous vous contacterons plus tard.")}</p>
            </div>
            <div className="step-final__content">
              <div className="step-final__home">
                <a href={`${baseURL}/careers`} className='kt-link'>
                  <span className="la la-arrow-left" />
                  {this.trans.get('Postulez de nouveau')}
                </a>
              </div>
              <div className="step-final__social-links">
                <h4>{this.trans.get('Suivez nous sur les Reseaux Sociaux')}</h4>
                <ul className="kt-footer__social-nav">
                  <li>
                    <a href="https://facebook.com/IUG.OFFICIEL" target="_blank" className="facebook" data-toggle="tooltip"
                       data-placement="top" title="" data-original-title="Facebook">
                      <i className="flaticon-facebook-letter-logo" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/iugOfficiel" target="_blank" className="twitter" data-toggle="tooltip" data-placement="top"
                       title="" data-original-title="Twitter">
                      <i className="flaticon-twitter-logo" />
                    </a>
                  </li>
                  <li>
                    <a href="https://linkedin.com" target="_blank" className="linkedin" data-toggle="tooltip" data-placement="top" title=""
                       data-original-title="LinkedIn">
                      <i className="flaticon-linkedin-logo" />
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com" target="_blank" className="instagram" data-toggle="tooltip" data-placement="top" title=""
                       data-original-title="Instagram">
                      <i className="socicon-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/00237691143731" target="_blank" className="whatsapp" data-toggle="tooltip" data-placement="top"
                       title="" data-original-title="WhatsApp">
                      <i className="flaticon-whatsapp" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        }

        <div className="modal fade" id="recapitulatif" tabIndex="-1" role="dialog" aria-labelledby="recapitulatifTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="recapitulatifTitle">{this.trans.get('Récapitulatif de vos informations')}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h4>{this.trans.get('Profil Sélectionné')}</h4>
                <p>{(profile === 'teacher') ? this.trans.get('Enseignant') : this.trans.get('Personnel Administratif')}</p>

                <h4>{this.trans.get('Informations Personnelles')}</h4>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get('Nom')}</label>
                    <label>{first_name}</label>
                  </div>
                  <div>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get('Prenom')}</label>
                    <label>{last_name}</label>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get('Date de naissance')}</label>
                    <label>{birth_date}</label>
                  </div>
                  <div>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get('Lieu de Naissance')}</label>
                    <label>{birth_place}</label>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get('Numéro de Teléphone')}</label>
                    <label>{phone_number}</label>
                  </div>
                  <div>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get('Adresse Email')}</label>
                    <label>{email}</label>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get('Quartier')}</label>
                    <label>{quarter}</label>
                  </div>
                  <div>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get('Sexe')}</label>
                    <label>{(gender === 'F') ? this.trans.get('Femme') : this.trans.get('Homme')}</label>
                  </div>
                </div>

                <h4>{this.trans.get('Informations Professionelles')}</h4>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get('Dernier Diplôme')}</label>
                    <label>{last_degree}</label>
                  </div>
                  <div>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get("Etablissement d'obtention")}</label>
                    <label>{last_degree_place}</label>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  { profile === 'teacher' &&
                    <div className='mr-4'>
                      <label className='mr-2 kt-font-bolder'>{this.trans.get('Status Recherché')}</label>
                      <label>{status}</label>
                    </div>
                  }
                  { profile === 'administrative' &&
                    <div className='mr-4'>
                      <label className='mr-2 kt-font-bolder'>{this.trans.get('Fonction')}</label>
                      <label>{fonction}</label>
                    </div>
                  }
                  <div>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get("Année d'experience")}</label>
                    <label>{experience_year}</label>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  {profile === 'teacher' &&
                    <div className='mr-4'>
                      <label className='mr-2 kt-font-bolder'>{this.trans.get('Matieres dispensées')}</label>
                      <label>{courses}</label>
                    </div>
                  }
                </div>

                <h4>{this.trans.get('Documents')}</h4>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get("Carte Nationale d'identité")}</label>
                    {cni_file !== '' && <span className="flaticon2-correct text-primary" />}
                    {cni_file === '' && <span className="flaticon-exclamation-1 text-danger" />}
                  </div>
                  <div>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get("Diplôme le plus élevé")}</label>
                    {last_degree_file !== '' && <span className="flaticon2-correct text-primary" />}
                    {last_degree_file === '' && <span className="flaticon-exclamation-1 text-danger" />}
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <div className='mr-4'>
                    <label className='mr-2 kt-font-bolder'>Curriculum Vitae (CV)</label>
                    {cv_file !== '' && <span className="flaticon2-correct text-primary" />}
                    {cv_file === '' && <span className="flaticon-exclamation-1 text-danger" />}
                  </div>
                  <div>
                    <label className='mr-2 kt-font-bolder'>{this.trans.get("Lettre de Motivation")}</label>
                    {cover_letter_file !== '' && <span className="flaticon2-correct text-primary" />}
                    {cover_letter_file === '' && <span className="flaticon-exclamation-1 text-danger" />}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-brand" data-dismiss="modal">{this.trans.get('Fermer')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StepFive.propTypes = {
  stepInfo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleStepBack: PropTypes.func,
  submitForm: PropTypes.func.isRequired,
  verifyCallback: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  complete: PropTypes.bool.isRequired
};

StepFive.defaultProps = {
  handleStepBack: () => {}
};

export default StepFive;
