import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import toastr from 'toastr';
import axios from 'axios';
import 'react-step-progress-bar/styles.css';

import IUGComponent from '../../IUGComponent';
import StepOne from '../forms/StepOne';
import StepTwo from '../forms/StepTwo';
import StepThree from '../forms/StepThree';
import StepFour from '../forms/StepFour';
import StepFive from '../forms/StepFive';
import StepSix from '../forms/StepSix';

class Admission extends IUGComponent {
  constructor(props) {
    super(props);

    this.state = {
      percent: 0,
      current: 1,
      formations: [],
      formation_name: '',
      speciality_name: '',
      loader: true,
      loading: false,
      isRobot: true,
      complete: false,
      step1: {
        number: 1,
        disabled: true,
        percent: 10,
        formation: ''
      },
      step2: {
        number: 2,
        disabled: true,
        percent: 20,
        groups: []
      },
      step3: {
        number: 3,
        disabled: true,
        percent: 40
      },
      step4: {
        number: 4,
        disabled: false,
        percent: 60
      },
      step5: {
        number: 5,
        disabled: false,
        percent: 80
      },
      step6: {
        number: 6,
        percent: 100
      },
      form: {
        speciality_id: '',
        formation_time: 'Jour',
        civility: 'Mr',
        name: '',
        lastname: '',
        email: '',
        phone_number: '',
        birthdate: '',
        birthdate_place: '',
        nationality: '',
        region: '',
        father_name: '',
        father_number: '',
        father_occupation: '',
        mother_name: '',
        mother_number: '',
        mother_occupation: '',
        last_degree: '',
        last_degree_year: '2012',
        last_degree_school: '',
        last_degree_file: null,
        birth_certificate: null,
        request: '',
        receive_letter: false,
        receive_copy: false
      }
    };
  }

  async componentDidMount() {
    if (this.state.current === 1) {
      const { data } = await axios.get('/api/formations');
      this.setState({ formations: data.data, loader: false })
    }
  }

  /**
   * Update step admission
   *
   * @param step
   */
  proceedToStep = (step) => {
    const { number, percent } = step;
    this.setState({ current: number, percent });
    window.scrollTo(0, 0);
  }

  /**
   * Handle Formation change
   *
   * @param event
   * @param name
   * @returns {Promise<void>}
   */
  handleOptionChange = async (event, name) => {
    const value = event.target.value;
    if (value === '') {
      toastr.error(this.trans.get('Vous devez choisir la formation !'));
    }

    this.setState({
      loading: true,
      formation_name: name,
      step1: Object.assign(this.state.step1, { formation: value })
    });

    const { data: { data: { specialities_group } } } = await axios.get('/api/formation/'+ value);

    this.setState({
      loading: false,
      step1: Object.assign(this.state.step1, { disabled: false }),
      step2: Object.assign(this.state.step2, { groups: specialities_group })
    });
  }

  /**
   * Handle change speciality
   *
   * @param event
   * @param name
   */
  handleSpecialityOption = (event, name) => {
    const value = event.target.value;
    if (value === '') {
      toastr.error(this.trans.get('Vous devez choisir une spécialité !'));
    }

    this.setState({
      speciality_name: name,
      form: Object.assign(this.state.form, { speciality_id: parseInt(value) }),
      step2: Object.assign(this.state.step2, { disabled: false })
    });
  }

  /**
   * Remove selected speciality when user back to step 2
   */
  removeSpecialityOption = () => {
    this.setState({
      form: Object.assign(this.state.form, { speciality_id: '' }),
      step2: Object.assign(this.state.step2, { disabled: true })
    });
  }

  /**
   * Handle inputs form change value
   *
   * @param event
   */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      form: Object.assign(this.state.form, { [name]: value })
    });
  }

  /**
   * Handle Quill editor change
   *
   * @param value
   */
  handleQuillChange = (value) => {
    this.setState({
      form: Object.assign(this.state.form, { request: value })
    });
  }

  /**
   * Handle change date
   *
   * @param event
   */
  handleChangeDate = (event) => {
    this.setState({
      form: Object.assign(this.state.form, { birthdate: event.target.value })
    });
  }

  /**
   * Select country picker
   *
   * @param val
   */
  selectCountry = (val) => {
    this.setState({
      form: Object.assign(this.state.form, { nationality: val })
    });
  }

  /**
   * Select region from country
   *
   * @param val
   */
  selectRegion = (val) => {
    this.setState({
      form: Object.assign(this.state.form, { region: val }),
      step3: Object.assign(this.state.step3, { disabled: false })
    });
  }

  /**
   * get file form dropzone
   *
   * @param controlName
   * @param file
   */
  onFileChange = (controlName, file) => {
    this.setState({
      form: Object.assign(this.state.form, { [controlName]: file })
    });
  }

  /**
   * Verify ReCaptcha
   *
   * @param recaptchaToken
   * @returns {Promise<void>}
   */
  recaptchaVerifyCallback = async (recaptchaToken) => {
    if (recaptchaToken) {
      try {
        const response = await axios.post('/api/verify/token', { token: recaptchaToken });
        const parsed = JSON.parse(response.data);
        if (parsed.success) {
          this.setState({ isRobot: false });
        }
      } catch (e) {
        console.log(e.response.data);
        toastr.error(this.trans.get("Erreur d'identification de votre identité"));
      }
    }
  }

  /**
   * Store admission to the database
   */
  handleSubmitForm = () => {
    const { isRobot, form, step6 } = this.state;

    if (!isRobot) {
      this.setState({ loading: true });
      axios.post('/api/admission/store', form).then(response => {
        this.setState({ loading: false, complete: true, isRobot: true });
        toastr.success(this.trans.get("Votre préinscription a été enrégistrée par l'établissement 👌🏾! "));
        this.proceedToStep(step6);
      }).catch(error => { 
        this.setState({ loading: false, error: true });
        toastr.error(error.response.data.message);

        const errors = error.response.data.errors;
        let emailMessage = '';

        if (errors.email !== undefined) {
          errors.email.map(er => { emailMessage = er})
          toastr.error(emailMessage);
        }
      })
    }
  }

  render() {
    const {
      percent,
      formations,
      current,
      loader,
      isRobot,
      loading,
      step1,
      step2,
      step3,
      step4,
      step5,
      step6,
      form,
      formation_name,
      speciality_name
    } = this.state;

    return (
      <div className='kt-admission'>
        <ProgressBar
          percent={percent}
          filledBackground="linear-gradient(to right, #5BBF22, #367A52)"
        >
          <Step>
            {({ accomplished, index }) => (
              <div className={`step ${accomplished ? "accomplished" : ""}`}>{index + 1}</div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div className={`step ${accomplished ? "accomplished" : ""}`}>{index + 1}</div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div className={`step ${accomplished ? "accomplished" : ""}`}>{index + 1}</div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div className={`step ${accomplished ? "accomplished" : ""}`}>{index + 1}</div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div className={`step ${accomplished ? "accomplished" : ""}`}>{index + 1}</div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div className={`step ${accomplished ? "accomplished" : ""}`}>{index + 1}</div>
            )}
          </Step>
        </ProgressBar>
        <div className="kt-steps">
          <StepOne
            stepInfo={step1}
            title={this.trans.get('Selectionner la Formation')}
            description={this.trans.get('Sélectionner la formation que vous souhaitez effectuer dans notre etablissement')}
            formations={formations}
            handleCompleteStep={() => this.proceedToStep(step2)}
            onChange={this.handleOptionChange}
            loader={loader}
            loading={loading}
            current={current}
            trans={this.trans}
          />
          <StepTwo
            stepInfo={step2}
            title={this.trans.get('Dans quelle catégorie vous situez-vous ?')}
            description={this.trans.get("Choisissez le groupe correspondant au domaine que vous souhaitez faire.")}
            handleCompleteStep={() => this.proceedToStep(step3)}
            handleStepBack={() => this.proceedToStep(step1)}
            onFormChange={this.handleFormChange}
            onChange={this.handleSpecialityOption}
            removeSelected={this.removeSpecialityOption}
            current={current}
            form={form}
          />
          <StepThree
            stepInfo={step3}
            title={this.trans.get("Informations Personnelles")}
            description={this.trans.get("Renseignez toutes vos informations personnelles pour nous permettre de vous recontactez plus facilement.")}
            handleCompleteStep={() => this.proceedToStep(step4)}
            handleStepBack={() => this.proceedToStep(step2)}
            onFormChange={this.handleFormChange}
            onDateChange={this.handleChangeDate}
            selectCountry={this.selectCountry}
            selectRegion={this.selectRegion}
            current={current}
            form={form}
            trans={this.trans}
          />
          <StepFour
            stepInfo={step4}
            title={this.trans.get("Précédente Formation")}
            description={this.trans.get("Renseignez les informations en rapport avec votre précédente formation vous donnant accès à la nouvelle que vous désirez poursuivre.")}
            onFormChange={this.handleFormChange}
            handleCompleteStep={() => this.proceedToStep(step5)}
            handleStepBack={() => this.proceedToStep(step3)}
            onFileChange={this.onFileChange}
            current={current}
            form={form}
          />
          {step5.number === current && <StepFive
            stepInfo={step5}
            title={this.trans.get("Finaliser la préinscription")}
            description={this.trans.get("Compléter les éléments suivant pour enregistrer votre préinscription")}
            current={current}
            form={form}
            formationName={formation_name}
            specialityName={speciality_name}
            onFormChange={this.handleFormChange}
            onQuillChange={this.handleQuillChange}
            handleCompleteStep={() => this.proceedToStep(step6)}
            handleStepBack={() => this.proceedToStep(step4)}
            verifyCallback={this.recaptchaVerifyCallback}
            submitForm={this.handleSubmitForm}
            disabled={isRobot}
            loading={loading}
          />}
          <StepSix
            stepInfo={step6}
            title={this.trans.get('Préinscription Terminée')}
            description={this.trans.get("Merci d'avoir effectué votre préinscription dans notre etablissement. Votre dossier sera vérifié et nous vous contacterons plus tard.")}
            current={current}
            trans={this.trans}
          />
        </div>
      </div>
    );
  }
}

export default Admission;
