import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinner-material';

const StepOne = ({
 stepInfo: { disabled, number, formation },
 title,
 formations,
 loader,
 onChange,
 description,
 current,
 loading,
 trans,
 handleCompleteStep
}) => {
  return (
    <div className={`kt-step step-1 ${number === current ? '' : 'd-none'}`}>
      <div className="step-1__header">
        <h1 className='step-1__title'>{title}</h1>
        <p>{description}</p>
      </div>
      <div className="row">
        { loader && <Spinner size={75} spinnerColor={"#F99F0D"} spinnerWidth={2} visible={true} /> }
        { !loader &&
          formations.map(item => {
            return (
              <div className="col-md-4 col-lg-3" key={item.id}>
                <label className="kt-option">
                  <span className="kt-option__control">
                    <span className="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold">
                      <input
                        type="radio"
                        name="formation"
                        value={item.slug}
                        checked={formation === item.slug}
                        onChange={(e) => onChange(e, item.name)}
                      />
                      <span />
                    </span>
                  </span>
                  <span className="kt-option__label">
                    <span className="kt-option__head">
                      <span className="kt-option__title">{item.name}</span>
                    </span>
                    <span className="kt-option__body">{item.subtitle}</span>
                  </span>
                </label>
              </div>
            );
          })
        }
      </div>
      <button className='btn btn-primary' onClick={handleCompleteStep} disabled={disabled}>
        {loading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />}
        {!loading && trans.get('Suivant')}
      </button>
    </div>
  );
}

StepOne.propTypes = {
  stepInfo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  formations: PropTypes.array,
  handleCompleteStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  loading: PropTypes.bool,
  current: PropTypes.number.isRequired
};

StepOne.defaultProps = {
  formations: [],
  loader: true,
  loading: false
};

export default StepOne;
