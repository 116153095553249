import React from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';

const StepThree = ({
  title,
  description,
  current,
  form: { profile, status },
  stepInfo: { number, disabled },
  handleCompleteStep,
  onChange,
  trans,
  handleStepBack
}) => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    handleCompleteStep();
  };

  return (
    <div className={`kt-step step-3 ${number === current ? '' : 'd-none'}`}>
      <div className="step-3__header mb-5">
        <h1 className='step-3__title'>{title}</h1>
        <p>{description}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="kt-form__section kt-form__section--first">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>{trans.get('Dernier Diplôme')}</label>
                <input
                  type="text"
                  className={`form-control ${errors.last_degree ? 'is-invalid' : ''}`}
                  ref={register({ required: true })}
                  name='last_degree'
                  placeholder='Ex:. Master 1 II, Licence CI, ...'
                  aria-describedby="high-diploma-error"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>{trans.get("Etablissement d'obtention")}</label>
                <input
                  type="text"
                  className={`form-control ${errors.last_degree_place ? 'is-invalid' : ''}`}
                  name='last_degree_place'
                  ref={register({ required: true })}
                  placeholder='Ex:. IUG de Douala...'
                  aria-describedby="place-diploma-error"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              { profile === 'teacher' &&
                <div className="form-group">
                  <label>{trans.get('Status Recherché')}</label>
                  <div className="kt-radio-list d-flex">
                    <label className="kt-radio">
                      <input
                        type="radio"
                        name="status"
                        checked={status === 'permanent'}
                        value="permanent"
                        onChange={onChange}
                      /> {trans.get('Permanent')}
                      <span />
                    </label>
                    <label className="kt-radio">
                      <input
                        type="radio"
                        name="status"
                        checked={status === 'vacataire'}
                        value="vacataire"
                        onChange={onChange}
                      /> {trans.get('Vacataire')}
                      <span />
                    </label>
                  </div>
                </div>
              }
              { profile === 'administrative' &&
              <div className="form-group">
                <label>{trans.get('Fonction')}</label>
                <input
                  type="text"
                  className={`form-control ${(profile === 'administrative' && errors.fonction) ? 'is-invalid' : ''}`}
                  ref={register({ required: profile === 'administrative' })}
                  aria-describedby="fonction-error"
                  name='fonction'
                  onChange={onChange}
                />
              </div>
              }
            </div>
            { profile === 'teacher' &&
              <div className="col-md-4">
                <div className="form-group">
                  <label>{trans.get('Matieres dispensées')}</label>
                  <input
                    type="text"
                    className={`form-control ${(profile === 'teacher' && errors.courses) ? 'is-invalid' : ''}`}
                    name='courses'
                    ref={register({ required: profile === 'teacher' })}
                    aria-describedby="courses-error"
                    onChange={onChange}
                    placeholder={trans.get('Précisez les matières en les séparant par des virgules (,)')}
                  />
                </div>
              </div>
            }
            <div className="col-md-2">
              <div className="form-group">
                <label>{trans.get("Année d'experience")}</label>
                <input
                  className={`form-control ${errors.experience_year ? 'is-invalid' : ''}`}
                  min="1"
                  type="number"
                  name='experience_year'
                  ref={register({ required: true })}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='buttons-action'>
          <button className='btn btn-default' onClick={handleStepBack}>
            {trans.get('Précedent')}
          </button>
          <button className='btn btn-primary' type='submit' disabled={disabled}>
            {trans.get('Suivant')}
          </button>
        </div>
      </form>
    </div>
  );
};

StepThree.propTypes = {
  stepInfo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleCompleteStep: PropTypes.func.isRequired,
  handleStepBack: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired
};

StepThree.defaultProps = {
  handleStepBack: () => {}
}

export default StepThree;
