import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';

if (document.getElementById('admission')) {
  ReactDOM.render(
    <App />,
    document.getElementById('admission')
  );
}
