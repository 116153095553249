import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import toastr from 'toastr';
import axios from 'axios';
import 'react-step-progress-bar/styles.css';

import IUGComponent from '../../IUGComponent';
import StepOne from "../forms/StepOne";
import StepTwo from "../forms/StepTwo";
import StepThree from "../forms/StepThree";
import StepFour from "../forms/StepFour";
import StepFive from "../forms/StepFive";

class Recruitment extends IUGComponent {
  constructor(props) {
    super(props);

    this.state = {
      percent: 0,
      current: 1,
      loading: false,
      isRobot: true,
      complete: false,
      step1: {
        number: 1,
        disabled: true,
        percent: 10
      },
      step2: {
        number: 2,
        disabled: false,
        percent: 25
      },
      step3: {
        number: 3,
        disabled: false,
        percent: 50
      },
      step4: {
        number: 4,
        disabled: false,
        percent: 75
      },
      step5: {
        number: 5,
        disabled: false,
        percent: 100
      },
      form: {
        profile: '',
        gender: 'M',
        first_name: '',
        last_name: '',
        birth_date: '',
        birth_place: '',
        quarter: '',
        email: '',
        phone_number: '',
        last_degree: '',
        last_degree_place: '',
        experience_year: '',
        status: '',
        fonction: '',
        courses: '',
        cni_file: '',
        cv_file: '',
        last_degree_file: '',
        cover_letter_file: ''
      }
    };
  }

  /**
   * Handle inputs form change value
   *
   * @param event
   */
  handleFormChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      form: Object.assign(this.state.form, { [name]: value })
    });
  }

  /**
   * Handle Formation change
   *
   * @param event
   * @returns {Promise<void>}
   */
  handleOptionChange = (event) => {
    const value = event.target.value;
    if (value === '') {
      toastr.error(this.trans.get('Vous devez choisir le profil pour continuer !'));
    }

    this.setState({
      step1: Object.assign(this.state.step1, { disabled: false }),
      form: Object.assign(this.state.form, { profile: value })
    });
  }

  /**
   * Update step admission
   *
   * @param step
   */
  proceedToStep = (step) => {
    const { number, percent } = step;
    this.setState({ current: number, percent });
  }

  /**
   * get file form dropzone
   *
   * @param controlName
   * @param file
   */
  onFileChange = (controlName, file) => {
    this.setState({
      form: Object.assign(this.state.form, { [controlName]: file })
    });
  }

  /**
   * Verify ReCaptcha
   *
   * @param recaptchaToken
   * @returns {Promise<void>}
   */
  recaptchaVerifyCallback = async (recaptchaToken) => {
    if (recaptchaToken) {
      try {
        const response = await axios.post('/api/verify/token', { token: recaptchaToken });
        const parsed = JSON.parse(response.data);
        if (parsed.success) {
          this.setState({ isRobot: false });
        }
      } catch (e) {
        console.log(e.response.data);
        toastr.error(this.trans.get("Erreur d'identification de votre identité"));
      }
    }
  }

  /**
   * Store admission to the database
   */
  handleSubmitForm = () => {
    const { isRobot, form } = this.state;

    if (!isRobot) {
      this.setState({ loading: true });
      axios.post('/api/recruitment/store', form).then(response => {
        this.setState({ loading: false, complete: true, isRobot: true });
        toastr.success(this.trans.get("Votre demande a été enrégistrée par l'établissement 👌🏾! "));
      }).catch(error => {
        this.setState({ loading: false, error: true });
        toastr.error(error.response.data.message);

        const errors = error.response.data.errors;
        let emailMessage = '';

        if (errors.email !== undefined) {
          errors.email.map(er => { emailMessage = er})
          toastr.error(emailMessage);
        }
      })
    }
  }

  render() {
    const {
      percent,
      current,
      isRobot,
      loading,
      complete,
      step1,
      step2,
      step3,
      step4,
      step5,
      form
    } = this.state;

    return (
      <div className="kt-recruitement-js">
        <div className="kt-recruitment-steper">
          <ProgressBar
            percent={percent}
            filledBackground="linear-gradient(to right, #5BBF22, #367A52)"
          >
            <Step>
              {({ accomplished, index }) => (
                <div className={`step ${accomplished ? "accomplished" : ""}`}>{index + 1}</div>
              )}
            </Step>
            <Step>
              {({ accomplished, index }) => (
                <div className={`step ${accomplished ? "accomplished" : ""}`}>{index + 1}</div>
              )}
            </Step>
            <Step>
              {({ accomplished, index }) => (
                <div className={`step ${accomplished ? "accomplished" : ""}`}>{index + 1}</div>
              )}
            </Step>
            <Step>
              {({ accomplished, index }) => (
                <div className={`step ${accomplished ? "accomplished" : ""}`}>{index + 1}</div>
              )}
            </Step>
            <Step>
              {({ accomplished, index }) => (
                <div className={`step ${accomplished ? "accomplished" : ""}`}>{index + 1}</div>
              )}
            </Step>
          </ProgressBar>
        </div>
        <div className='kt-portlet'>
          <div className='kt-portlet__body'>
            <div className="kt-steps">
              <StepOne
                stepInfo={step1}
                title={this.trans.get('Je Postule en tant que')}
                description={this.trans.get("Selectionner le profil avec lequel vous souhaitez postuler au sein de l'IUG !")}
                handleCompleteStep={() => this.proceedToStep(step2)}
                onChange={this.handleOptionChange}
                current={current}
                trans={this.trans}
                form={form}
              />
              <StepTwo
                stepInfo={step2}
                title={this.trans.get('Informations Personnelles')}
                description={this.trans.get('Renseignez toutes vos informations personnelles pour nous permettre de vous recontactez plus facilement.')}
                onChange={this.handleFormChange}
                handleCompleteStep={() => this.proceedToStep(step3)}
                handleStepBack={() => this.proceedToStep(step1)}
                current={current}
                trans={this.trans}
                form={form}
              />
              <StepThree
                stepInfo={step3}
                title={this.trans.get('Informations Professionelles')}
                description={this.trans.get('Renseignez les informations en rapport avec votre dernière formation et sur le poste pour lequel vous postulez.')}
                handleCompleteStep={() => this.proceedToStep(step4)}
                handleStepBack={() => this.proceedToStep(step2)}
                onChange={this.handleFormChange}
                current={current}
                trans={this.trans}
                form={form}
              />
              <StepFour
                stepInfo={step4}
                title={this.trans.get('Documents')}
                description={this.trans.get('Uploader tous les documents demandés ci-dessous.')}
                handleCompleteStep={() => this.proceedToStep(step5)}
                handleStepBack={() => this.proceedToStep(step3)}
                onFileChange={this.onFileChange}
                current={current}
                trans={this.trans}
                form={form}
              />
              {step5.number === current && <StepFive
                stepInfo={step5}
                title={this.trans.get("Finaliser votre demande")}
                description={this.trans.get("Compléter les éléments suivant pour envoyer votre demande.")}
                current={current}
                form={form}
                handleStepBack={() => this.proceedToStep(step4)}
                verifyCallback={this.recaptchaVerifyCallback}
                submitForm={this.handleSubmitForm}
                disabled={isRobot}
                loading={loading}
                complete={complete}
              />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Recruitment;
