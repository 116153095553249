import React from 'react';
import PropTypes from 'prop-types';

const StepSix = ({
 stepInfo: { number },
 title,
 description,
 current,
 trans
}) => {
  const baseURL = document.head.querySelector('meta[name="base-url"]').content;

  return (
    <div className={`kt-step step-6 ${number === current ? '' : 'd-none'}`}>
      <div className="step-6__header">
        <span className="flaticon2-correct text-primary" />
        <h1 className='step-6__title'>{title}</h1>
        <p>{description}</p>
      </div>
      <div className="step-6__content">
        <div className="step-6__home">
          <a href={`${baseURL}`} className='kt-link'>
            <span className="la la-arrow-left" />
            {trans.get('Retourner sur le site')}
          </a>
        </div>
        <div className="step-6__social-links">
          <h4>{trans.get('Suivez nous sur les Reseaux Sociaux')}</h4>
          <ul className="kt-footer__social-nav">
            <li>
              <a href="https://facebook.com/IUG.OFFICIEL" target="_blank" className="facebook" data-toggle="tooltip"
                 data-placement="top" title="" data-original-title="Facebook">
                <i className="flaticon-facebook-letter-logo" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/iugOfficiel" target="_blank" className="twitter" data-toggle="tooltip" data-placement="top"
                 title="" data-original-title="Twitter">
                <i className="flaticon-twitter-logo" />
              </a>
            </li>
            <li>
              <a href="https://linkedin.com" target="_blank" className="linkedin" data-toggle="tooltip" data-placement="top" title=""
                 data-original-title="LinkedIn">
                <i className="flaticon-linkedin-logo" />
              </a>
            </li>
            <li>
              <a href="https://instagram.com" target="_blank" className="instagram" data-toggle="tooltip" data-placement="top" title=""
                 data-original-title="Instagram">
                <i className="socicon-instagram" />
              </a>
            </li>
            <li>
              <a href="https://wa.me/00237691143731" target="_blank" className="whatsapp" data-toggle="tooltip" data-placement="top"
                 title="" data-original-title="WhatsApp">
                <i className="flaticon-whatsapp" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

StepSix.propTypes = {
  stepInfo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  current: PropTypes.number.isRequired,
  trans: PropTypes.object.isRequired,
};

export default StepSix;
