import React from 'react';

import MainPage from './pages';

class RecruitmentApp extends React.Component {
  render() {
    return (
      <MainPage/>
    );
  }
}

export default RecruitmentApp;
