import React from 'react';
import PropTypes from 'prop-types';

import IUGComponent from '../../IUGComponent';

class StepTwo extends IUGComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      selected: '',
      specialities: []
    }

    this.buttons = React.createRef();
  }

  /**
   * Select group to display specialities
   *
   * @param group
   */
  handleGroupClick = (group) => {
    this.setState({
      selected: group.slug,
      specialities: group.specialities,
      name: group.name
    });
  }

  /**
   * Return to previous step
   */
  handleStepBack = () => {
    this.setState({ specialities: [], selected: '', name: '' });
    this.props.removeSelected();
    this.props.handleStepBack();
  }

  /**
   * On select speciality scroll to next step button
   *
   * @param event
   * @param name
   */
  handleOnChange = (event, name) => {
    this.buttons.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });

    this.props.onChange(event, name);
  }

  render() {
    const { selected, specialities, name } = this.state;
    const {
      stepInfo: { number, groups, disabled },
      title,
      current,
      description,
      onFormChange,
      form: { speciality_id, formation_time },
      handleCompleteStep
    } = this.props;

    return (
      <div className={`kt-step step-2 ${number === current ? '' : 'd-none'}`}>
        <div className="step-2_group">
          <div className="step-2__header">
            <h1 className='step-2__title'>{title}</h1>
            <p>{description}</p>
          </div>
          <div className="step-2_content">
            <ul className="groups-list">
              {
                groups.map(group => {
                  return (
                    <li key={group.id}>
                      <a onClick={() => this.handleGroupClick(group)} className={`${selected === group.slug ? 'selected' : '' }`}>
                        <span className="flaticon-folder-1"/>
                        {(selected === group.slug) && <span className="selected"><span className="flaticon2-correct" /></span>}
                        {group.name}
                      </a>
                    </li>
                  );
                })
              }
            </ul>
            <div className="kt-separator kt-separator--space-lg kt-separator--border-dashed" />
            <div className="form-group">
              <label>{this.trans.get('Formation en Journée')}</label>
              <div className="kt-radio-list d-flex">
                <label className="kt-radio mr-3">
                  <input
                    type="radio"
                    name="formation_time"
                    checked={formation_time === 'Jour'}
                    value="Jour"
                    onChange={onFormChange}
                  /> {this.trans.get('Oui')}
                  <span />
                </label>
                <label className="kt-radio">
                  <input
                    type="radio"
                    name="formation_time"
                    checked={formation_time === 'Nuit'}
                    value="Nuit"
                    onChange={onFormChange}
                  /> {this.trans.get('Non')}
                  <span />
                </label>
              </div>
            </div>
            <div className='buttons-action' ref={this.buttons}>
              <button className='btn btn-default' onClick={this.handleStepBack}>
                {this.trans.get('Précedent')}
              </button>
              <button className='btn btn-primary' onClick={handleCompleteStep} disabled={disabled}>
                {this.trans.get('Suivant')}
              </button>
            </div>
          </div>
        </div>
        <div className="step-2_speciality">
          {(specialities.length <= 0) &&
            <div className="empty-specialities">
              <span className='flaticon-squares-1' />
              <h5>{this.trans.get('Sélectionnez le groupe pour faire apparaître les filières associées')}</h5>
            </div>
          }
          {(specialities.length > 0) &&
            <div className='pt-3'>
              <h3>{name}</h3>
              <ul className='specialities-group'>
                {
                  specialities.map(speciality => {
                    return (
                      <li key={speciality.id}>
                        <label className="kt-radio kt-radio--bold kt-radio--primary">
                          <input
                            type="radio"
                            name="speciality_id"
                            value={speciality.id}
                            checked={speciality_id === speciality.id}
                            onChange={(event) => this.handleOnChange(event, speciality.name)}
                          /> {speciality.name}
                          <span />
                        </label>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          }
        </div>
      </div>
    );
  }
}

StepTwo.propTypes = {
  stepInfo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleCompleteStep: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  removeSelected: PropTypes.func.isRequired,
  handleStepBack: PropTypes.func,
  current: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired
};

StepTwo.defaultProps = {
  handleStepBack: () => {}
};

export default StepTwo;
