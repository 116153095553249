import React from 'react';
import PropTypes from 'prop-types';

import image from '../../../assets/apply.svg';

const StepOne = ({
   stepInfo: { disabled, number },
   title,
   loader,
   onChange,
   description,
   current,
   loading,
   trans,
   form: { profile },
   handleCompleteStep
}) => {
    return (
      <div className={`kt-step step-1 ${number === current ? '' : 'd-none'}`}>
        <div className="step-1__header">
          <img src={image} />
          <h1 className='step-1__title'>{title}</h1>
          <p>{description}</p>
        </div>
        <div className="step-1__content">
          <div>
            <label className="kt-option">
              <span className="kt-option__control">
                <span className="kt-radio kt-radio--bold kt-radio--brand kt-radio--check-bold" checked>
                  <input
                    type="radio"
                    name="profile"
                    value="teacher"
                    checked={profile === 'teacher'}
                    onChange={onChange}
                  />
                  <span />
                </span>
              </span>
              <span className="kt-option__label">
                <span className="kt-option__head">
                  <span className="kt-option__title">{trans.get('Enseignant')}</span>
                </span>
                  <span className="kt-option__body">
                    {trans.get('Postuler comme Enseignant dans notre etablissement pour participer à la formation de nos étudiants.')}
                  </span>
              </span>
            </label>
          </div>
          <div>
            <label className="kt-option">
              <span className="kt-option__control">
                <span className="kt-radio kt-radio--bold kt-radio--brand">
                  <input
                    type="radio"
                    name="profile"
                    value="administrative"
                    checked={profile === 'administrative'}
                    onChange={onChange}
                  />
                  <span />
                </span>
              </span>
              <span className="kt-option__label">
                <span className="kt-option__head">
                  <span className="kt-option__title">{trans.get('Personnel Administratif')}</span>
                </span>
                <span className="kt-option__body">
                  {trans.get('Postuler comme Personnel Administratif pour assurer le bon fonctionnement de notre etablissement.')}
                </span>
              </span>
            </label>
          </div>
        </div>
        <button className='btn btn-primary' onClick={handleCompleteStep} disabled={disabled}>
          {trans.get('Suivant')}
        </button>
      </div>
    );
};

StepOne.propTypes = {
  stepInfo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleCompleteStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired
};

export default StepOne;
